import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import StickyBottomButtons from './stickyButtonsJustMobile'; // Import the new component
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import './footer.css';
import configDetails from './../components/utils/ConfigDetails';
const Footer = () => {
    const { t } = useTranslation(); // Correctly using React hooks text-align: center;  
    const numar = configDetails.swFormatted;
    const wapp = configDetails.wappswFormatted;
    const email = configDetails.email;
    const vatnumber = configDetails.vatnumber;
    
    return (
      <Container id='footerIDpaddingtopandbott'style={{ textAlign: 'center', marginTop: '20px', background:'black', padding:'50px', color:'white', width:'100%!important', maxWidth:'100%', paddingBottom: '0px', paddingTop:'20px', bottom:'0'}}>
          <div className='MenuIconNoDesktop'>
      <StickyBottomButtons  phoneNumber={numar} whatsappNumber={wapp} /> {/* Sticky buttons */}
      </div>
        <Typography>Phone: {numar} | Email: {email} | VAT#: {vatnumber}</Typography>
        <Button color="inherit" component={Link} to="/" className="" style={{color:'#c8853e'}}>
          {t('HomePage')} {/* Home */}
        </Button>
        |
        <Button color="inherit" component={Link} to="/company" className=""  style={{color:'#c8853e'}}>
          {t('Company')} {/* Contact */}
        </Button>
        |
        <Button color="inherit" component={Link} to="/services" className=""  style={{color:'#c8853e'}}>
          {t('Services')} {/* Contact */}
        </Button>
        |
        <Button color="inherit" component={Link} to="/transport" className="" style={{color:'#c8853e'}}>
          {t('Transport')} {/* Contact */}
        </Button>
        |
        <Button color="inherit" component={Link} to="/export" className="" style={{color:'#c8853e'}}>
          {t('Export')} {/* Contact */}
        </Button>
        |
        <Button color="inherit" component={Link} to="/find-us" className="" style={{color:'#c8853e'}}>
          {t('WhereToFindUs')} {/* Contact */}
        </Button>
|
        <Button color="inherit" component={Link} to="/contact" className="" style={{color:'#c8853e'}}>
          {t('ContactPage')} {/* Contact */}
        </Button>
        
       <br></br><div style={{visibility:'hidden'}}>asd</div>
        <Typography variant="body2">
         {t('footerCopyright')}
        </Typography>
        <br></br>
      </Container>
    );
  };
  
  export default Footer;
