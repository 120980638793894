import React, { useEffect, useRef } from 'react';
import './FullWidthSlider.css'; // Import your custom CSS for animations (if needed)
import BusinessStatus from './BusinessStatus'; 

const FullWidthVideo = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5; // Set video playback speed (e.g., 1.5x)
    }
  }, []);

  return (
    <div style={{ width: '100%', height: 'auto' }} className='sliderOnMobile'>
      {/* Full-Width Video with 50% height */}
      <video
        ref={videoRef}
        style={{ width: '100%', height: '50vh', objectFit: 'cover' }}  // Set the height to 50% of the viewport height
        muted
        autoPlay
        loop
      >
        <source src="/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Display the business status below the video */}
      <BusinessStatus className="openCss"/> 
    </div>
  );
};

export default FullWidthVideo;
